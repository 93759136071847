    .animated-dots {
    display: block;
    font-size: 800px;
    color: transparent;
  }
  
  .animated-dots::before, .animated-dots::after {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 2em;
    height: 2em;
    content: '.';
    mix-blend-mode: multiply;
    animation: move 44s infinite ease-in-out alternate;
    text-shadow: var(--dots);
  }
  
  .animated-dots::before {
    animation-duration: 44s;
    animation-delay: -27s;
  }
  
  .animated-dots::after {
    animation-duration: 43s;
    animation-delay: -32s;
  }
  
  @keyframes move {
    from {
      transform: rotate(0deg) scale(12) translateX(-20px);
    }
    to {
      transform: rotate(360deg) scale(18) translateX(20px);
    }
  }

 