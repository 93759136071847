
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Fira+Sans:wght@400;500;700&family=Roboto:wght@300;400;700&display=swap');


html{
  font-size: 14px;
  }
  @media (min-width: 992px){
    html{
      font-size: 16px;
     
      }
  }


.logoNav{
  height: 3rem;
}

body{
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  
}

#root{
  overflow-x: hidden;
  width: 100%;
  margin-top: 88px;
}

.container{
  --bs-gutter-x: 2.5rem;
}

.font1{
  font-family: 'Anton', sans-serif;
}

.font2{
  font-family: 'Fira Sans', sans-serif;
}


 .containerFullHeight{
  height: calc(100vh - 160px);
 }
 .xLogo{
  height: 30vh;
 }

.snixLogo{
  height: 20vh;
}

 .footerContainer {
position: relative;
z-index: 1;
 }

 .iconsNav{
  height: 2rem;
 }

 .bg-brand{
  background-color: #fbf7ee;
 }

 .main-navbar {
  height: auto;
  padding: 0;
  background-color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  transition: ease 0.4s;
  z-index: 1000;
 transition: background-color .5s;
}

.main-navbar.scrolled {
  background-color: white;
  transition: background-color 2s;

}

.unreLogo{
  position: relative;
  z-index: 1;
  height: 30vh;
}